import React, {Component} from 'react'
import './style.less';
import Map from '../Map'
import {graphql, StaticQuery} from "gatsby";

class DiscoverSnowdoniaMapComponent extends Component {
    render() {
        return (
            <Map {...this.props}/>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query LocationMapList {
                allWordpressWpAmenity(sort: {fields: title, order: ASC}) {
                    nodes {
                        title
                        wordpress_id
                        translations {
                            current
                            cy
                            en
                        }
                    }
                },
                allWordpressWpLocation {
                    nodes {
                        wordpress_id
                        acf {
                            latitude
                            longitude
                            member {
                                amenities
                                featured_image {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 680, maxHeight: 175, quality: 80, cropFocus: CENTER) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                                membership_level
                                name
                                path
                                snippet
                                snowdonia_pass
                                website_url
                                wordpress_id
                            },
                            name
                            type
                        },
                        translations {
                            current
                            cy
                            en
                        }
                    }
                },
                allWordpressWpLocationType(sort: {fields: acf___label, order: ASC}) {
                    nodes {
                        acf {
                            class
                            label
                            marker_icon {
                                localFile {
                                    url
                                }
                            }
                            listing_colour
                        },
                        translations {
                            current
                            cy
                            en
                        },
                        wordpress_id
                        wordpress_parent
                    }
                },
                allWordpressWpPlace {
                    nodes {
                        acf {
                            excerpt
                            meta_title
                            meta_description
                            og_title
                            og_description
                            twitter_card_type
                            twitter_title
                            twitter_description
                            banner_video
                            kml_region_file
                            latitude
                            longitude
                            zoom
                            place_blurb
                        }
                        translations {
                            en
                            cy
                            current
                        }
                        title
                        wordpress_id
                    }
                },
                allWordpressAcfOptions {
                    nodes {
                        options {
                            map_default_location_type
                        }
                    }
                }
            }
        `}
        render={(data) => {
            let locations = data.allWordpressWpLocation.nodes.map(function(location){
                let loc = location.acf;
                loc.wordpress_id = location.wordpress_id;
                loc.translations = location.translations;
                return loc;
            });
            return <DiscoverSnowdoniaMapComponent
                amenities={data.allWordpressWpAmenity.nodes}
                locations={locations}
                locationTypes={data.allWordpressWpLocationType.nodes}
                options={data.allWordpressAcfOptions.nodes[0].options}
                regions={data.allWordpressWpPlace.nodes}
                {...props}
            />
        }}
    />
)
